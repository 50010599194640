import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "engage-toolbar d-flex position-fixed px-5 fw-bolder zindex-2 top-50 end-0 transform-90 mt-20 gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("button", {
      id: "kt_engage_demos_toggle",
      class: "engage-demos-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0",
      title: "Check out more demos",
      "data-bs-toggle": "tooltip",
      "data-bs-placement": "left",
      "data-bs-dismiss": "click",
      "data-bs-trigger": "hover"
    }, [
      _createElementVNode("span", { id: "kt_engage_demos_label" }, " Demos ")
    ], -1),
    _createElementVNode("button", {
      id: "kt_help_toggle",
      class: "engage-help-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm px-5 rounded-top-0",
      title: "Learn & Get Inspired",
      "data-bs-toggle": "tooltip",
      "data-bs-placement": "left",
      "data-bs-dismiss": "click",
      "data-bs-trigger": "hover"
    }, " Help ", -1),
    _createElementVNode("a", {
      href: "#",
      target: "_blank",
      class: "engage-purchase-link btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 px-5 shadow-sm rounded-top-0"
    }, " Buy Now ", -1)
  ])))
}